import React from "react";

function Hero() {
  return (
    <section className="hero">
      <h1>Custom One-on-One Guitar Lessons in your home.</h1>
      <p>
        Unlock your musical potential with personalized guitar lessons tailored
        to your unique style, pace, and goals. Whether you’re a beginner looking
        to strum your first chord or an experienced guitarist aiming to perfect
        your technique, we are here to guide you every step of the way. With
        flexible scheduling and in-home lessons, we make learning convenient and
        enjoyable for all skill levels. Don’t wait to start your musical
        journey. <br />
        <br />
        Book your first lesson today and experience the power of one-on-one
        instruction!
      </p>
      <a
        class="cta-a btn"
        target="_blank"
        href="https://calendly.com/dvkdesign/guitar-lesson-introductory-phone-call"
      >
        Schedule a Lesson
      </a>
    </section>
  );
}

export default Hero;
