import React from "react";
import { Helmet } from "react-helmet";

function Header() {
  return (
    <>
      {/* Helmet is used to manage meta tags and title */}
      <Helmet>
        <title>Parkland Guitar Lessons</title>
        <meta
          name="description"
          content="Custom One-on-One Guitar Lessons in your home."
        />
        <meta
          name="keywords"
          content="Guitar Lessons, One-on-One Lessons, Music Lessons"
        />
        <meta name="author" content="Dan Kariamis" />
      </Helmet>

      {/* Header content */}
      <header className="header">
        <div className="logo">
          <span class="h1">Parkland Guitar Lessons</span>
          <p>Your personal instructor to mastering the guitar.</p>
        </div>
        <div className="contact">
          <h2>
            Questions? <span>Call 954-249-8837</span>
          </h2>
        </div>
      </header>
    </>
  );
}

export default Header;
