import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Link } from "react-router-dom";
import "./styles/main.scss";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Benefits from "./components/Benefits";
import Testimonials from "./components/Testimonials";
import LeadForm from "./components/LeadForm";
import Footer from "./components/Footer";
import GuitarLessonsPage from "./components/ParklandGuitarLessonsPage";
import GuitarLessonsBocaRaton from "./components/GuitarLessonsBocaRaton";
import ParklandGuitarTutor from "./components/ParklandGuitarTutor";
import AdultGuitarLessonsParkland from "./components/AdultGuitarLessonsParkland";
import GuitarLessonsInCoralSprings from "./components/GuitarLessonsInCoralSprings";
import GuitarLessonsInCoconutCreek from "./components/GuitarLessonsInCoconutCreek";
import GuitarLessonsInDeerfieldBeach from "./components/GuitarLessonsInDeerfieldBeach";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        {/* Home page route */}
        <Route
          path="/"
          element={
            <>
              <Hero />
              <Benefits />
              <Testimonials />
              <LeadForm />
              <p class="keyword-links">
                Explore a variety of guitar lesson options across South Florida!
                Whether you're looking for{" "}
                <Link to="/guitar-lessons-parkland-fl">
                  guitar lessons in Parkland
                </Link>
                ,{" "}
                <Link to="/guitar-lessons-boca-raton-fl">
                  guitar lessons in Boca Raton
                </Link>
                ,{" "}
                <Link to="/guitar-lessons-coral-springs-fl">
                  guitar lessons in Coral Springs
                </Link>
                , or nearby areas, we've got you covered. You can find tailored
                lessons for all ages and skill levels, including specialized
                options like{" "}
                <Link to="/adult-guitar-lessons-parkland">
                  adult guitar lessons in Parkland
                </Link>
                . Check out our detailed pages for the best{" "}
                <Link to="/parkland-guitar-tutor">
                  guitar tutor in Parkland
                </Link>
                , or dive into lessons specifically designed for Coral Springs,{" "}
                <Link to="/guitar-lessons-coconut-creek-fl">
                  guitar lessons in Coconut Creek
                </Link>
                , or{" "}
                <Link to="/guitar-lessons-deerfield-beach-fl">
                  guitar lessons in Deerfield Beach
                </Link>
                . No matter where you are, we're here to help you become the
                guitarist you've always wanted to be.
              </p>
            </>
          }
        />

        <Route
          path="/guitar-lessons-parkland-fl"
          element={<GuitarLessonsPage />}
        />
        <Route
          path="/guitar-lessons-boca-raton-fl"
          element={<GuitarLessonsBocaRaton />}
        />
        <Route
          path="/parkland-guitar-tutor"
          element={<ParklandGuitarTutor />}
        />
        <Route
          path="/adult-guitar-lessons-parkland"
          element={<AdultGuitarLessonsParkland />}
        />
        <Route
          path="/guitar-lessons-coral-springs-fl"
          element={<GuitarLessonsInCoralSprings />}
        />
        <Route
          path="/guitar-lessons-coral-springs-fl"
          element={<GuitarLessonsInCoconutCreek />}
        />
        <Route
          path="/guitar-lessons-coral-springs-fl"
          element={<GuitarLessonsInDeerfieldBeach />}
        />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
