import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4"; // Use react-ga4 for GA4

const TRACKING_ID = "G-W7MB5Q62LC"; // GA4 Tracking ID

ReactGA.initialize(TRACKING_ID); // Initialize GA4
ReactGA.send("pageview"); // Send initial pageview event

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
