import React from "react";

function Testimonials() {
  const testimonials = [
    {
      name: "John Doe",
      review: "Amazing lessons! My playing improved so much.",
    },
    { name: "Jane Smith", review: "Best guitar teacher I’ve ever had!" },
  ];

  return (
    <section className="testimonials">
      <h2>What Our Students Say</h2>
      <div className="testimonial-container">
        <div className="testimonial">
          <p>
            "I’ve tried online tutorials and group classes, but nothing compares
            to the personalized attention I get in my one-on-one lessons. My
            instructor tailors each lesson to my specific goals, which has made
            all the difference. In just a few months, I’ve gone from barely
            strumming to confidently playing full songs. These lessons have
            reignited my passion for guitar!"
          </p>
          <strong>- Bruce M.</strong>
        </div>
        <div className="testimonial">
          <p>
            "As someone who’s never played guitar before, I was nervous about
            starting. But my teacher made me feel at ease right from the
            beginning. The lessons are fun, relaxed, and easy to follow. Plus,
            being able to schedule lessons around my busy life has been a huge
            relief. I never thought I’d be playing guitar, and now I can’t
            imagine not playing. Highly recommend!"
          </p>
          <strong>- Jane S.</strong>
        </div>
        <div className="testimonial">
          <p>
            "The convenience of in-home lessons is unbeatable. I no longer waste
            time commuting, and my instructor is incredibly patient and
            knowledgeable. I’ve been playing for years, but my technique has
            improved dramatically since starting these lessons. It’s refreshing
            to have such personalized feedback and guidance. Five stars!"
          </p>
          <strong>- Sarah J.</strong>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
