import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <p>
        Contact us at:{" "}
        <a href="mailto:danielvkariamis@gmail.com" target="_blank">
          Send an Email
        </a>
      </p>
      <p>
        Phone: (954) 249-8837 | Available 9 AM - 9 PM, Monday through Friday
      </p>
      <p>
        Follow us on social media to stay updated on our latest lesson tips,
        performance opportunities, and more!
      </p>
      <div className="socials">{/* Social Media Icons */}</div>
      <p>
        &copy; 2024 One-on-One Guitar Lessons. All rights reserved. Let’s make
        music together!
      </p>
    </footer>
  );
}

export default Footer;
