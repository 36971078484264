import React from "react";
import Image1 from "../assets/lessons.png";
import Image2 from "../assets/Aulas-online.webp";
import Image3 from "../assets/d5121b_df5d887dd9b74ac2bac6cf20c5137a8b~mv2.webp";
import Image4 from "../assets/Aulas-Dupla---2.webp";
import Image5 from "../assets/3423a89692ebcbbc229c787e9bc7305c_428x278_119x98_655x926_crop.jpg";

function Benefits() {
  const benefits = [
    "Personalized lessons for all levels",
    "Flexible scheduling to fit your lifestyle",
    "Learn at your own pace with a dedicated instructor",
  ];

  return (
    <section className="benefits">
      <h2>Why Choose Parkland Guitar Lessons?</h2>
      <ul>
        <li>
          <img src={Image1} alt="My Image" width="570" height="339" />
          <p>
            <strong>Personalized Lessons Tailored to You</strong> — We believe
            every student has their own learning style. That’s why we customize
            each lesson to focus on your personal interests and strengths.
            Whether you're looking to learn your favorite songs, improve
            technique, or master music theory, our instructors will guide you in
            a way that fits your pace and style.
          </p>
        </li>
        <li>
          <img src={Image2} alt="My Image" width="570" height="339" />
          <p>
            <strong>Flexible Scheduling</strong> — We understand that life is
            busy. With flexible lesson times and the ability to reschedule
            without hassle, we make it easy for you to fit guitar lessons into
            your life, not the other way around. Learn at your own pace without
            the pressure of a rigid schedule.
          </p>
        </li>
        <li>
          <img src={Image3} alt="My Image" width="570" height="339" />
          <p>
            <strong>Experienced Instructors</strong> — Our instructors are not
            only skilled guitarists but also passionate educators. They bring
            years of experience to the table, helping you avoid common mistakes
            and fast-tracking your progress. Whether you’re just starting out or
            looking to refine advanced techniques, our instructors will guide
            you through each step.
          </p>
        </li>
        <li>
          <img src={Image4} alt="My Image" width="570" height="339" />
          <p>
            <strong>In-Home Lessons</strong> — No more commuting or squeezing
            lessons into an already packed day. We bring the music to you! Enjoy
            learning in the comfort of your home, with lessons designed to help
            you practice effectively in your own space. This convenience gives
            you more time to focus on what matters — making music.
          </p>
        </li>
        <li>
          <img src={Image5} alt="My Image" width="570" height="339" />
          <p>
            <strong>All Ages and Skill Levels Welcome</strong> — Whether you’re
            8 or 80, we welcome students of all ages and experience levels. From
            first-time players to seasoned musicians looking for a fresh
            perspective, our one-on-one lessons are tailored to suit your
            personal goals and aspirations.
          </p>
        </li>
      </ul>
    </section>
  );
}

export default Benefits;
